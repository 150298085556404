import '@/containers/PageLayout/components/RouterProgressBar/RouterProgressBar.scss';
import { IconContext } from '@react-icons/all-files';
import 'focus-visible/dist/focus-visible.min.js';
import { AppProps as NextAppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
// next-i18next 14.0.3 is used instead of the latests 15th version,
// because of the next issue
// https://github.com/i18next/next-i18next/issues/2253
// Also, see the coments about the language selection
// https://dev.azure.com/swapfiets/Digital/_workitems/edit/27220
import { appWithTranslation } from 'next-i18next';
import { IntlErrorCode, NextIntlClientProvider } from 'next-intl';
import React from 'react';
import { useEffectOnce } from 'react-use';
import { addCustomConsentOnDecline } from '@/lib/cookiebot/addCustomConsentOnDecline';
import { gtmInitialDataLayer } from '@/lib/gtm/gtmInitialDataLayer';
import { gtmSnippets } from '@/lib/gtm/gtmSnippets';
import { useGtmPageView } from '@/lib/gtm/useGtmPageView';
import { initializeStatsig } from '@/lib/statsig';
import { isBrowser } from '@/utils/isBrowser';
import '../styles/fonts.scss';
import '../styles/main.scss';

interface NextAppPropsWithError extends NextAppProps {
  err: unknown;
  // flaky workaround for https://github.com/vercel/next.js/issues/8592
}

const generatedGtmSnippets = (function () {
  if (process.env.NEXT_PUBLIC_ENV === 'production') {
    return gtmSnippets({
      gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER!,
    });
  } else {
    return gtmSnippets({
      gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER!,
      auth: process.env.NEXT_PUBLIC_GTM_AUTH!,
      preview: process.env.NEXT_PUBLIC_GTM_PREVIEW!,
      events: {
        gtm_cookies_win: 'x',
      },
    });
  }
})();

initializeStatsig();

function MyApp({ Component, pageProps, err, router }: NextAppPropsWithError) {
  const { locale } = router;
  useGtmPageView();

  useEffectOnce(() => {
    if (isBrowser) {
      addCustomConsentOnDecline({ statistics: true, preference: true });
      gtmInitialDataLayer(locale!);
    }
  });

  return (
    <>
      <Head>
        <style key="async-hide">{'.async-hide { opacity: 0 !important}'}</style>
      </Head>
      <Script strategy="lazyOnload" id="initiateGtmScriptEvent">
        {generatedGtmSnippets.initiateGtmScriptEvent}
      </Script>
      <Script
        src={generatedGtmSnippets.gtmScriptSrc}
        strategy="lazyOnload"
        id="gtmScript"
        key="loadGtmScript"
      />
      <Script
        id="trustPilot"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        strategy="lazyOnload"
      />
      <IconContext.Provider value={{ size: '20px' }}>
        <NextIntlClientProvider
          locale={locale}
          messages={pageProps.messages}
          now={new Date()}
          onError={error => {
            // use the user time zone and suppress the error
            if (error.code === IntlErrorCode.ENVIRONMENT_FALLBACK) {
              return undefined;
            }
          }}
        >
          <Component {...pageProps} err={err} />
        </NextIntlClientProvider>
      </IconContext.Provider>
    </>
  );
}

export default appWithTranslation(MyApp);
